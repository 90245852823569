<template>
  <div class="sales-order-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
    <b-modal v-model="modal" centered hide-header-close
             ok-variant="danger"
             cancel-variant="custom-light"
             v-bind:ok-disabled="!password || !orderNo"
             v-on:ok="deleteSalesOrder"
             v-on:hide="resetModal">
      <h3 slot="modal-title">Delete Sales Order</h3>
      <h5 slot="modal-ok" class="m-0">Confirm</h5>
      <h5 slot="modal-cancel" class="m-0">Cancel</h5>
      <form action="" class="container-modal-form position-relative w-100 h-100 p-3">
        <div class="form-group position-relative">
          <label for="password" class="font-weight-bold">Password</label>
          <input type="password" id="password" class="form-control position-relative w-100"
                 v-bind:class="{'invalid': pwInvalid}"
                 v-model="password"
                 v-on:input="pwInvalid = false">
        </div>
        <div class="form-group position-relative">
          <label for="modalSalesOrderNo" class="font-weight-bold">Order No.</label>
          <input type="text" id="modalSalesOrderNo" class="form-control position-relative w-100"
                 v-bind:class="{'invalid': orderNoInvalid}"
                 v-model="orderNo"
                 v-on:input="orderNoInvalid = false">
        </div>
      </form>
    </b-modal>
    <div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
      <form-controls v-bind:msg="msg"
                     v-bind:msg-invalid="msgInvalid">
        <control-button slot="left"
                        v-bind:disabled="false"
                        v-bind:btn-title="backBtnTitle"
                        v-bind:btn-icon="['fas', 'arrow-left']"
                        v-bind:btn-class="backBtnClass"
                        v-on:btn-pressed="goBack"></control-button>
        <control-button slot="right"
                        v-bind:disabled="!savable"
                        btn-title="SAVE"
                        v-bind:btn-icon="['fas', 'hdd']"
                        btn-class="btn-custom-light ml-2"
                        v-on:btn-pressed="saveSalesOrder"></control-button>
        <control-button slot="right"
                        v-bind:disabled="!salesOrder"
                        btn-title="DELETE"
                        v-bind:btn-icon="['fas', 'trash-alt']"
                        btn-class="btn-danger ml-2"
                        v-on:btn-pressed="modal = true"></control-button>
      </form-controls>
      <form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
        <div class="form-group position-relative w-100">
          <label for="salesOrderNo" class="font-weight-bold">Order No.</label>
          <input type="text" id="salesOrderNo" placeholder=""
                 class="form-control position-relative"
                 v-bind:value="localSalesOrder.orderNo" readonly>
        </div>
        <div class="form-group position-relative w-100">
          <label for="salesQuote" class="font-weight-bold">Sales Quote</label>
          <input type="text" id="salesQuote" class="form-control position-relative"
                 placeholder="Choose A Sales Quote From The Table"
                 v-bind:class="invalidInputs.salesQuote"
                 v-bind:value="localSalesOrder.quoteNo" readonly>
        </div>
        <div class="form-group position-relative w-100">
          <label for="client" class="font-weight-bold">Client</label>
          <input type="text" id="client" class="form-control position-relative"
                 placeholder="Choose Your Intended Client From The Table"
                 v-bind:class="invalidInputs.client"
                 v-bind:value="localSalesOrder.clientName" readonly>
        </div>
        <div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
             v-for="(item, index) in localSalesOrder.items" v-bind:key="index">
          <div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
            <button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
                    v-bind:disabled="localSalesOrder.items.length === 1"
                    v-on:click="addDeleteItems('delete', index)">
              <font-awesome-icon class="position-relative w-90 h-100"
                                 v-bind:icon="['fas', 'minus']">
              </font-awesome-icon>
            </button>
            <h4 class="title position-relative px-4 m-0 text-truncate">Item {{index + 1}}</h4>
            <button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
                    v-bind:disabled="localSalesOrder.items.length >= 50"
                    v-on:click="addDeleteItems('add', index)">
              <font-awesome-icon class="position-relative w-90 h-100"
                                 v-bind:icon="['fas', 'plus']">
              </font-awesome-icon>
            </button>
          </div>
          <div class="content position-relative w-100 p-3">
            <div class="form-group position-relative w-100">
              <label v-bind:for="`item-name${index}`" class="font-weight-bold">Name</label>
              <input type="text" v-bind:id="`item-name${index}`" placeholder="Item Name"
                     class="form-control position-relative"
                     v-bind:class="invalidItems[index].name"
                     v-bind:value="item.name"
                     v-on:input="updateItems('name', $event.target.value, index)">
            </div>
            <div class="form-group position-relative w-100">
              <label v-bind:for="`item-quantity${index}`" class="font-weight-bold">Quantity</label>
              <input type="text" v-bind:id="`item-quantity${index}`" placeholder="Quantity"
                     class="form-control position-relative"
                     v-bind:class="invalidItems[index].quantity"
                     v-bind:value="item.quantity"
                     v-on:input="updateItems('quantity', $event.target.value, index)">
            </div>
            <div class="form-group position-relative w-100">
              <label v-bind:for="`item-unitPrice${index}`" class="font-weight-bold">Unit Price</label>
              <input type="text" v-bind:id="`item-unitPrice${index}`" placeholder="Unit Price"
                     class="form-control position-relative"
                     v-bind:class="invalidItems[index].unitPrice"
                     v-bind:value="item.unitPrice"
                     v-on:change="updateItems('unitPrice', $event.target.value, index)">
            </div>
          </div>
        </div>
        <div class="form-group position-relative w-100">
          <label for="currency" class="font-weight-bold">Currency</label>
          <select id="currency" class="form-control position-relative"
                  v-bind:class="invalidInputs.currency"
                  v-bind:value="localSalesOrder.currency"
                  v-on:change="updateSalesOrder('currency', $event.target.value)">
            <option disabled hidden value=""></option>
            <option>HKD</option>
            <option>USD</option>
            <option>RMB</option>
            <option>JPY</option>
            <option>CAD</option>
          </select>
        </div>
        <div class="form-group position-relative w-100">
          <label for="serviceFee" class="font-weight-bold">Service Fee</label>
          <input type="text" id="serviceFee" placeholder="Service Fee"
                 class="form-control position-relative"
                 v-bind:class="invalidInputs.serviceFee"
                 v-bind:value="localSalesOrder.serviceFee"
                 v-on:change="updateSalesOrder('serviceFee', $event.target.value)">
        </div>
        <div class="form-group position-relative w-100">
          <label for="shippingFee" class="font-weight-bold">Shipping Fee</label>
          <input type="text" id="shippingFee" placeholder="Shipping Fee"
                 class="form-control position-relative"
                 v-bind:class="invalidInputs.shippingFee"
                 v-bind:value="localSalesOrder.shippingFee"
                 v-on:change="updateSalesOrder('shippingFee', $event.target.value)">
        </div>
        <div class="form-group position-relative w-100">
          <label for="otherCharges" class="font-weight-bold">Other Charges</label>
          <input type="text" id="otherCharges" placeholder="Other Charges"
                 class="form-control position-relative"
                 v-bind:class="invalidInputs.otherCharges"
                 v-bind:value="localSalesOrder.otherCharges"
                 v-on:change="updateSalesOrder('otherCharges', $event.target.value)">
        </div>
        <div class="form-group position-relative w-100">
          <label for="tax" class="font-weight-bold">Tax</label>
          <input type="text" id="tax" placeholder="Tax"
                 class="form-control position-relative"
                 v-bind:class="invalidInputs.tax"
                 v-bind:value="localSalesOrder.tax"
                 v-on:change="updateSalesOrder('tax', $event.target.value)">
        </div>
        <div class="form-group position-relative w-100">
          <label for="discount" class="font-weight-bold">Discount</label>
          <input type="text" id="discount" placeholder="Discount"
                 class="form-control position-relative"
                 v-bind:class="invalidInputs.discount"
                 v-bind:value="localSalesOrder.discount"
                 v-on:change="updateSalesOrder('discount', $event.target.value)">
        </div>
        <div class="form-group position-relative w-100">
          <label for="totalAmount" class="font-weight-bold">Total Amount</label>
          <input type="text" id="totalAmount" placeholder="0.00"
                 class="form-control position-relative"
                 v-bind:value="localSalesOrder.totalAmount" readonly>
        </div>
        <div class="form-group position-relative w-100">
          <label for="paymentTerms" class="font-weight-bold">Payment Terms</label>
          <input type="text" id="paymentTerms" placeholder="Payment Terms"
                 class="form-control position-relative"
                 v-bind:class="invalidInputs.paymentTerms"
                 v-bind:value="localSalesOrder.paymentTerms"
                 v-on:input="updateSalesOrder('paymentTerms', $event.target.value)">
        </div>
        <div class="container-datetime-pickers position-relative d-flex">
          <div class="date-picker-form-group form-group position-relative d-flex flex-column">
            <label for="deliveryDate" class="font-weight-bold text-truncate">Delivery Date</label>
            <b-form-datepicker id="deliveryDate" placeholder="Date"
                               class="date-picker"
                               v-bind:class="invalidInputs.issuedAtDT.date"
                               v-bind:value="localSalesOrder.issuedAtDT.date"
                               v-on:input="updateDT('issuedAt', 'issuedAtDT', 'date', $event)">
            </b-form-datepicker>
          </div>
          <div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
            <label for="DeliveryTime" class="font-weight-bold text-truncate">Delivery Time</label>
            <b-form-timepicker id="DeliveryTime" placeholder="Time"
                               class="time-picker"
                               v-bind:class="invalidInputs.issuedAtDT.time"
                               v-bind:value="localSalesOrder.issuedAtDT.time"
                               v-on:input="updateDT('issuedAt', 'issuedAtDT', 'time', $event)">
            </b-form-timepicker>
          </div>
        </div>
        <div class="form-group position-relative w-100">
          <label for="issuedBy" class="font-weight-bold">Order Issued By</label>
          <input type="text" id="issuedBy" class="form-control position-relative"
                 placeholder="Choose Among Your Staffs Who Issued This Order"
                 v-bind:class="invalidInputs.issuedBy"
                 v-bind:value="localSalesOrder.issuedByName" readonly>
        </div>
        <div class="container-datetime-pickers position-relative d-flex">
          <div class="date-picker-form-group form-group position-relative d-flex flex-column">
            <label for="issuedAtDate" class="font-weight-bold text-truncate">Date Issued</label>
            <b-form-datepicker id="issuedAtDate" placeholder="Date"
                               class="date-picker"
                               v-bind:class="invalidInputs.issuedAtDT.date"
                               v-bind:value="localSalesOrder.issuedAtDT.date"
                               v-on:input="updateDT('issuedAt', 'issuedAtDT', 'date', $event)">
            </b-form-datepicker>
          </div>
          <div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
            <label for="issuedAtTime" class="font-weight-bold text-truncate">Time Issued</label>
            <b-form-timepicker id="issuedAtTime" placeholder="Time"
                               class="time-picker"
                               v-bind:class="invalidInputs.issuedAtDT.time"
                               v-bind:value="localSalesOrder.issuedAtDT.time"
                               v-on:input="updateDT('issuedAt', 'issuedAtDT', 'time', $event)">
            </b-form-timepicker>
          </div>
        </div>
        <div class="form-group position-relative w-100">
          <label for="packing-list" class="font-weight-bold">Packing List</label>
          <textarea id="packing-list" rows="3" class="form-control position-relative"
                    v-model="localSalesOrder.notes"></textarea>
        </div>
        <div class="form-group position-relative w-100">
          <label for="notes" class="font-weight-bold">Notes</label>
          <textarea id="notes" rows="6" class="form-control position-relative"
                    v-model="localSalesOrder.notes"></textarea>
        </div>
        <div class="form-group position-relative w-100">
          <label for="updatedBy" class="font-weight-bold">Order Updated By</label>
          <input type="text" id="updatedBy" class="form-control position-relative"
                 placeholder="Order Not Yet Saved"
                 v-bind:value="localSalesOrder.updatedByName" readonly>
        </div>
        <div class="container-datetime-pickers position-relative d-flex">
          <div class="date-picker-form-group form-group position-relative d-flex flex-column">
            <label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
            <b-form-datepicker id="updatedAtDate" placeholder="Date"
                               class="date-picker"
                               v-bind:value="localSalesOrder.updatedAtDT.date" disabled>
            </b-form-datepicker>
          </div>
          <div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
            <label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
            <b-form-timepicker id="updatedAtTime" placeholder="Time"
                               class="time-picker"
                               v-bind:value="localSalesOrder.updatedAtDT.time" disabled>
            </b-form-timepicker>
          </div>
        </div>
        <div class="form-group position-relative w-100">
          <label for="createdBy" class="font-weight-bold">Order Created By</label>
          <input type="text" id="createdBy" class="form-control position-relative"
                 placeholder="Order Not Yet Created"
                 v-bind:value="localSalesOrder.createdByName" readonly>
        </div>
        <div class="container-datetime-pickers position-relative d-flex">
          <div class="date-picker-form-group form-group position-relative d-flex flex-column">
            <label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
            <b-form-datepicker id="createdAtDate" placeholder="Date"
                               class="date-picker"
                               v-bind:value="localSalesOrder.createdAtDT.date" disabled>
            </b-form-datepicker>
          </div>
          <div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
            <label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
            <b-form-timepicker id="createdAtTime" placeholder="Time"
                               class="time-picker"
                               v-bind:value="localSalesOrder.createdAtDT.time" disabled>
            </b-form-timepicker>
          </div>
        </div>
      </form>
    </div>
    <div class="table-selects scroll-bar position-relative w-50 h-100 ml-3 rounded">
      <table-select title="Sales Quote" filter="quoteNo" sort="quoteNo"
                    v-bind:order="1"
                    v-bind:fields="salesQuoteFields"
                    v-bind:data-array="salesQuotes"
                    v-bind:selectable="true"
                    v-bind:multi-selectable="false"
                    v-bind:selected-ids="[localSalesOrder.salesQuote]"
                    v-on:row-clicked="selectSalesQuote($event)"
                    v-bind:style="{height: '50% !important'}"></table-select>
      <table-select title="Client" filter="name" sort="name"
                    v-bind:order="1"
                    v-bind:fields="clientsFields"
                    v-bind:data-array="clients"
                    v-bind:selectable="true"
                    v-bind:multi-selectable="false"
                    v-bind:selected-ids="[localSalesOrder.client]"
                    v-on:row-clicked="selectClient($event)"
                    v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
      <table-select title="Staff" filter="name" sort="name"
                    v-bind:order="1"
                    v-bind:fields="staffsFields"
                    v-bind:data-array="staffs"
                    v-bind:selectable="true"
                    v-bind:multi-selectable="false"
                    v-bind:selected-ids="[localSalesOrder.issuedBy]"
                    v-on:row-clicked="selectIssuedBy($event)"
                    v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
    </div>
  </div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent, itemsDifferent, calculateCharges, discountInvalid } from "@/utility/helpers";

export default {
  name: "SalesOrderDetails",

  components: {
    FormControls,
    TableSelect,
    ControlButton,
    BModal,
    BFormDatepicker,
    BFormTimepicker
  },

  props: {
    // props from vue router route params
    salesOrderId: {
      type: String,
    }
  },

  beforeRouteLeave(to, from, next) {
    this.modal = false;
    if (this.savable) {
      if (this.msg === "Leave Without Saving?") {
        // user has already been notified
        next();
      } else {
        this.toRoute = to.fullPath;
        this.msg = "Leave Without Saving?";
        this.msgInvalid = true;
        this.backBtnTitle = "YES!";
        this.backBtnClass = "btn-danger mr-2";
      }
    } else {
      next();
    }
  },

  created() {
    this.initSalesOrder();
    window.addEventListener("beforeunload", this.unloadHandler);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.unloadHandler);
  },

  data() {
    return {
      localSalesOrder: {
        _id: "",
        orderNo: "",
        salesQuote: "",
        client: "",
        items: [
          {
            name: "",
            quantity: "",
            unitPrice: "",
          },
        ],
        currency: "",
        serviceFee: "",
        shippingFee: "",
        tax: "",
        otherCharges: "",
        discount: "",
        totalAmount: "",
        paymentTerms: "",
        issuedAt: "",
        createdAt: "",
        updatedAt: "",
        issuedBy: "",
        createdBy: "",
        updatedBy: "",
        notes: "",
        // added properties
        clientName: "",
        quoteNo: "",
        issuedByName: "",
        createdByName: "",
        updatedByName: "",
        issuedAtDT: { date: "", time: "" },
        createdAtDT: { date: "", time: "" },
        updatedAtDT: { date: "", time: "" },
      },
      // use v-model on input that does not require validation
      invalidInputs: {
        client: "",
        salesQuote: "",
        currency: "",
        paymentTerms: "",
        serviceFee: "",
        shippingFee: "",
        tax: "",
        otherCharges: "",
        discount: "",
        // can be invalid but not required
        issuedAtDT: { date: "", time: "" },
        issuedBy: "",
      },
      invalidItems: [
        {
          name: "",
          quantity: "",
          unitPrice: "",
        },
      ],
      clientsFields: [
        { display: "Name", key: "name", classBinding: ["f-4"] },
        { display: "Client No.", key: "clientNo", classBinding: ["f-3"] },
        { display: "Type", key: "type", classBinding: ["f-2"] },
      ],
      salesQuoteFields: [
        { display: "Quote No.", key: "quoteNo", classBinding: ["f-3"] },
        { display: "Client", key: "client", classBinding: ["f-4"] },
        { display: "Items", key: "items", classBinding: [] },
        { display: "Currency", key: "currency", classBinding: [] },
        { display: "Total", key: "totalAmount", classBinding: ["f-2"] },
      ],
      staffsFields: [
        { display: "Name", key: "name", classBinding: ["f-4"] },
        { display: "Staff No.", key: "staffNo", classBinding: ["f-3"] },
        { display: "Gender", key: "gender", classBinding: [] },
        { display: "Position", key: "position", classBinding: ["f-4"] },
      ],
      msg: "",
      msgInvalid: false,
      modal: false,
      toRoute: "",
      backBtnTitle: "BACK",
      backBtnClass: "btn-custom-light mr-2",
      password: "",
      pwInvalid: false,
      orderNo: "",
      orderNoInvalid: false,
    }
  },

  computed: {
    salesOrder() {
      return this.$store.state.contents.salesOrders.find(({ _id }) => _id === this.salesOrderId);
    },

    savable() {
      // fall through pattern
      if (!this.salesOrder) return true;
      // can safely access properties inside this.salesOrder due to the first if clause in this function
      if (itemsDifferent(this.localSalesOrder.items, this.salesOrder.items)) return true;
      if (dateTimeDifferent(this.salesOrder.issuedAt, this.localSalesOrder.issuedAt)) return true;
      const keys = ["client", "salesQuote", "currency", "serviceFee", "shippingFee", "tax", "otherCharges",
        "discount", "paymentTerms", "issuedBy", "notes"];
      return keys.some(key => this.localSalesOrder[key] !== this.salesOrder[key]);
    },

    clients() {
      return this.$store.state.contents.clients.map(({ _id, name, clientNo, type }) => {
        return {
          _id: _id,
          name: name,
          clientNo: clientNo,
          type: type
        }
      });
    },

    salesQuotes() {
      return this.$store.state.contents.salesQuotations.map(({ _id, quoteNo, client, items, currency, totalAmount}) => {
        const clientFound = this.$store.state.contents.clients.find(({ _id }) => _id === client);
        return {
          _id: _id,
          quoteNo: quoteNo,
          client: clientFound ? `${clientFound.name} (${clientFound.clientNo})` : "N/A",
          items: items.length,
          currency: currency,
          totalAmount: totalAmount
        }
      });
    },

    staffs() {
      return this.$store.state.contents.staffs.map(({ _id, firstName, lastName, preferredName, staffNo, position, gender }) => {
        return {
          _id: _id,
          name: `${lastName} ${firstName}, ${preferredName}`,
          staffNo: staffNo,
          gender: gender,
          position: position
        };
      });
    },
  },

  methods: {
    unloadHandler(event) {
      if (this.savable) {
        event.preventDefault();
        event.returnValue = '';
      }
    },

    resetModal(bvModalEvent) {
      if (bvModalEvent.trigger !== "ok") {
        this.password = "";
        this.pwInvalid = false;
        this.orderNo = "";
        this.orderNoInvalid = false;
      }
    },

    initSalesOrder() {
      if (this.salesOrder) {
        const obj = {...this.salesOrder};
        obj.items = [];
        this.invalidItems = [];
        this.salesOrder.items.forEach(item => {
          obj.items.push({...item});
          this.invalidItems.push({ name: "", quantity: "", unitPrice: "" });
        });
        const client = this.$store.state.contents.clients.find(({ _id }) => _id === obj.client);
        const salesQuote = this.$store.state.contents.salesQuotations.find(({ _id }) => _id === obj.salesQuote);
        const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.issuedBy);
        const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
        const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
        // createdAt and updatedAt must present in the database, need no nullish check
        const createdAtDT = new Date(obj.createdAt);
        const updatedAtDT = new Date(obj.updatedAt);
        // added properties
        obj.clientName = client ? `${client.name} (${client.clientNo})` : "";
        obj.quoteNo = salesQuote ? salesQuote.quoteNo : "";
        obj.issuedByName = issuedBy ? `${issuedBy.lastName} ${issuedBy.firstName}, ${issuedBy.preferredName} (${issuedBy.staffNo})` : "";
        obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
        obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
        obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
        obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
        if (obj.issuedAt) {
          const issuedAtDT = new Date(obj.issuedAt);
          obj.issuedAtDT = { date: format(issuedAtDT, "yyyy-MM-dd"), time: format(issuedAtDT, "HH:mm:ss")};
        } else {
          obj.issuedAtDT = { date: "", time: "" };
        }
        this.localSalesOrder = obj;
      } else {
        this.localSalesOrder._id = uuidv4();
        this.localSalesOrder.orderNo = "SO-" + this.localSalesOrder._id.split("-")[0].toUpperCase();
      }
    },

    addDeleteItems(option, index) {
      if (option === "add") {
        this.localSalesOrder.items.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
        this.invalidItems.splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
      } else {
        this.localSalesOrder.items.splice(index, 1);
        this.invalidItems.splice(index, 1);
      }
    },

    selectClient(client) {
      this.localSalesOrder.client = client._id;
      this.localSalesOrder.clientName = `${client.name} (${client.clientNo})`;
      this.invalidInputs.client = "";
    },

    selectSalesQuote(quote) {
      this.localSalesOrder.salesQuote = quote._id;
      this.localSalesOrder.quoteNo = quote.quoteNo;
      this.invalidInputs.salesQuote = "";
    },

    selectIssuedBy(staff) {
      this.localSalesOrder.issuedBy = staff._id;
      this.localSalesOrder.issuedByName = `${staff.name} (${staff.staffNo})`;
      this.invalidInputs.issuedBy = "";
    },

    // this method also set discount class to invalid if discountInvalid returns true
    updateTotalAmount() {
      const totalCharges = calculateCharges(this.localSalesOrder);
      if (discountInvalid(totalCharges, this.localSalesOrder.discount)) {
        this.invalidInputs.discount = "invalid";
        this.localSalesOrder.totalAmount = totalCharges.toFixed(2);
      } else {
        this.invalidInputs.discount = "";
        const totalAmount = totalCharges - parseFloat(this.localSalesOrder.discount);
        this.localSalesOrder.totalAmount = totalAmount.toFixed(2);
      }
    },

    updateSalesOrder(key, value) {
      this.localSalesOrder[key] = value;
      switch (key) {
        case "serviceFee":
        case "shippingFee":
        case "tax":
        case "otherCharges":
          if (isNumeric(value) && parseFloat(value) >= 0) {
            this.localSalesOrder[key] = parseFloat(value).toFixed(2);
            this.invalidInputs[key] = "";
          } else {
            this.invalidInputs[key] = "invalid";
          }
          this.updateTotalAmount();
          break;
        case "discount":
          if (isNumeric(value) && parseFloat(value) >= 0) this.localSalesOrder[key] = parseFloat(value).toFixed(2);
          this.updateTotalAmount();
          break;
        default:
          // check if value is just white spaces
          this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
          break;
      }
    },

    updateDT(key, keyDT, dateTime, value) {
      this.localSalesOrder[keyDT][dateTime] = value;
      this.invalidInputs[keyDT][dateTime] = "";
      if (dateTime === "date") {
        this.localSalesOrder[key] = this.localSalesOrder[keyDT].time ?
            new Date(`${value}T${this.localSalesOrder[keyDT].time}`).toISOString() : value;
      } else {
        this.localSalesOrder[key] = this.localSalesOrder[keyDT].date ?
            new Date(`${this.localSalesOrder[keyDT].date}T${value}`).toISOString() : value;
      }
    },

    updateItems(key, value, index) {
      this.localSalesOrder.items[index][key] = value;
      switch (key) {
        case "quantity":
          this.invalidItems[index][key] = isNumeric(value) && parseFloat(value) >= 0 ? "" : "invalid";
          this.updateTotalAmount();
          break;
        case "unitPrice":
          // bind to change event instead of input event
          if (isNumeric(value) && parseFloat(value) >= 0) {
            this.localSalesOrder.items[index][key] = parseFloat(value).toFixed(2);
            this.invalidItems[index][key] = "";
          } else {
            this.invalidItems[index][key] = "invalid";
          }
          this.updateTotalAmount();
          break;
        default:
          this.invalidItems[index][key] = value.trim().length > 0 ? "" : "invalid";
          break;
      }
    },

    // only set invalid, shouldn't set it back to empty string
    // nullish check only, no other validations since they are being taken care of in input event handlers
    dataInvalid() {
      const orderKeys = ["client", "salesQuote", "currency", "paymentTerms", "serviceFee", "shippingFee", "tax",
        "otherCharges", "discount"];
      const itemKeys = ["name", "quantity", "unitPrice"];
      orderKeys.forEach(key => {
        if (!this.localSalesOrder[key] ||
            this.localSalesOrder[key].trim().length === 0) this.invalidInputs[key] = "invalid";
      });
      this.invalidItems.forEach((item, index) => itemKeys.forEach(key => {
        if (!this.localSalesOrder.items[index][key] ||
            this.localSalesOrder.items[index][key].trim().length === 0) item[key] = "invalid"; //object reference
      }));
      if (this.localSalesOrder.issuedBy || this.localSalesOrder.issuedAt) {
        if (!this.localSalesOrder.issuedBy)this.invalidInputs.issuedBy = "invalid";
        if (!this.localSalesOrder.issuedAtDT.date) this.invalidInputs.issuedAtDT.date = "invalid";
        if (!this.localSalesOrder.issuedAtDT.time) this.invalidInputs.issuedAtDT.time = "invalid";
      }
      return Object.keys(this.invalidInputs).some(key => {
        if (key === "issuedAtDT") {
          return this.invalidInputs.issuedAtDT.date === "invalid" ||
              this.invalidInputs.issuedAtDT.time === "invalid";
        } else {
          return this.invalidInputs[key] === "invalid";
        }
      }) || this.invalidItems.some(item => itemKeys.some(key => item[key] === "invalid"));
    },

    async saveSalesOrder() {
      this.backBtnTitle = "BACK";
      this.backBtnClass = "btn-custom-light mr-2";
      if (this.dataInvalid()) {
        this.msg = "Please Fix Invalid Fields!";
        this.msgInvalid = true;
      } else {
        const deleteKeys = ["clientName", "quoteNo", "issuedByName", "createdByName", "updatedByName",
          "issuedAtDT", "createdAtDT", "updatedAtDT"];
        const now = new Date().toISOString();
        const salesOrder = {...this.localSalesOrder};
        if (!salesOrder.createdAt) {
          salesOrder.createdAt = now;
          salesOrder.createdBy = this.$store.state.user.user._id;
        }
        salesOrder.updatedAt = now;
        salesOrder.updatedBy = this.$store.state.user.user._id;
        deleteKeys.forEach(key => delete salesOrder[key]);
        await this.$store.dispatch("contents/updateContent", { key: "salesOrders", value: salesOrder});
        if (this.salesOrderId) {
          this.initSalesOrder();
          this.msg = "Previous Changes Saved!";
          this.msgInvalid = false;
        } else {
          // bypassing beforeRouteLeave guard
          this.msg = "Leave Without Saving?";
          await this.$router.push(`/sales/order/${salesOrder._id}`);
          // need to run initSalesOrder after route change since salesOrderId prop was undefined
          this.initSalesOrder();
          // vue router reuses this component
          this.msgInvalid = false;
          this.msg = "New Sales Order Created!";
        }
      }
    },

    async deleteSalesOrder(bvModalEvent) {
      if (this.password !== this.$store.state.user.user.password) {
        bvModalEvent.preventDefault();
        this.pwInvalid = true;
        if (this.orderNo !== this.salesOrder.orderNo) this.orderNoInvalid = true;
      } else if (this.orderNo !== this.salesOrder.orderNo) {
        bvModalEvent.preventDefault();
        this.orderNoInvalid = true;
      } else {
        await this.$store.dispatch("contents/deleteContent", { key: "salesOrders", _id: this.salesOrder._id});
        // bypassing beforeRouteLeave guard
        this.msg = "Leave Without Saving?";
        await this.$router.push("/sales/order");
      }
    },

    async goBack() {
      if (this.msg === "Leave Without Saving?") {
        await this.$router.push(this.toRoute);
      } else {
        await this.$router.push("/sales/order");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
@import "src/assets/scss/table_overflow";
</style>
